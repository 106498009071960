<template>
  <div class="pro_advan">
    <h2>{{ $t("L_PRODUCT.CONTENT_0004") }}</h2>
    <ul class="pro_advan_main">
      <li v-for="(item, index) in advanList" :key="index">
        <div class="pro_advan_img" :class="'pro_advan_img' + item.id">
          <span :class="'pro_img' + item.id"></span>
        </div>
        <div class="pro_advan_title">
          {{ item.title }}
        </div>
        <div class="pro_advan_content">
          {{ item.content }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "advan",
  data() {
    return {
      advanList: [
        {
          id: 1,
          title: this.$t("L_PRODUCT.CONTENT_1004"),
          content: this.$t("L_PRODUCT.CONTENT_1005"),
        },
        {
          id: 2,
          title: this.$t("L_PRODUCT.CONTENT_1006"),
          content: this.$t("L_PRODUCT.CONTENT_1007"),
        },
        {
          id: 3,
          title: this.$t("L_PRODUCT.CONTENT_1008"),
          content: this.$t("L_PRODUCT.CONTENT_1009"),
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/house/advan";
</style>