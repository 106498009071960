<template>
  <div class="product-house-wrapper">
    <banner ref="banner"></banner>
    <func ref="func"></func>
    <advan ref="advan"></advan>
    <action ref="action"></action>
  </div>
</template>

<script>
import banner from "@/components/pc/product/house/banner.vue";
import func from "@/components/pc/product/house/func.vue";
import advan from "@/components/pc/product/house/advan.vue";
import action from "@/components/pc/product/house/action.vue";
export default {
  name: "house",
  components: {
    banner,
    func,
    advan,
    action
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/styles/pc/product/house/index.scss";
</style>