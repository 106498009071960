<template>
  <div class="pro_action">
    <h2>{{ $t("L_PRODUCT.CONTENT_0013") }}</h2>
    <ul class="pro_action_main">
      <li v-for="(item, index) in actionList" :key="index">
        <img :src="item.imgURl" />
        <p>{{ item.title }}</p>
      </li>
    </ul>
    <div class="pro_action_btn">
      <a :class="btnClass" :href="link" target="_blank" v-if="btnClass !== 'no'" @click.prevent="linkClick(link)">{{ btnText }}</a>
      <a :class="btnClass" :href="link" v-else @click="handleShowTip">{{ btnText }}</a>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="400"
        :before-close="handleClose"
    >
      <p>{{$t("L_MAIN.BUTTON_0006")}}</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{$t("L_SYSTEM_TIP.TIP_0002")}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "action",
  data() {
    return {
      actionList: [
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1010"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1011"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1012"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1013"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1014"),
        },
        {
          imgURl: "",
          title: this.$t("L_PRODUCT.CONTENT_1015"),
        }
      ],
      link: null,
      btnText: null,
      btnClass: "",
      dialogTitle: this.$t("L_SYSTEM_TIP.TIP_0001"),
      dialogButton: this.$t("L_SYSTEM_TIP.TIP_0002"),
      dialogVisible: false
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.handleRenderButton();
      this.renderImages();
    })
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    getStaticData(name) {
      return require(`@/assets/image/pc/product/house/action/changj0${name}.png`);
    },
    renderImages() {
      this.actionList.forEach((item, index) => {
        let i = index + 1;
        item.imgURl = this.getStaticData(i);
      })
    },
    handleRenderButton() {
      if (this.userInfo !== "" && this.userInfo.function) {
        let statusArr = this.userInfo.function.split(",");
        if (statusArr[1] === "1") {
          this.link = "#/error?state=2";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0002");
          this.btnClass = "use";
        } else {
          this.link = "javascript:;";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0003");
          this.btnClass = "no";
        }
      } else {
        this.link = "#/login?type=2";
        this.btnText = this.$t("L_PRODUCT.BUTTON_0001");
        this.btnClass = "try";
      }
    },
    handleShowTip() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    linkClick(link) {
      if (!this.loginStatus) {
        this.$router.push({ path: "/login?type=2" });
      } else {
        if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
          window.open(link);
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/house/action";
</style>