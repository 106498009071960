<template>
  <div class="pro_banner">
    <div class="pro_banner_img" :style="{height: height}">
      <div class="pro_banner_txt">
        <h2>{{ $t("L_PRODUCT.TITLE_0002") }}</h2>
        <p class="p1">{{ $t("L_PRODUCT.CONTENT_1001") }}</p>
        <p class="p2">
          <a :class="btnClass" :href="link" target="_blank" v-if="btnClass !== 'no'" @click.prevent="linkClick(link)">{{ btnText }}</a>
          <a :class="btnClass" :href="link" v-else @click="handleShowTip">{{ btnText }}</a>
        </p>
      </div>
    </div>
    <el-dialog
        :title="dialogTitle"
        :visible.sync="dialogVisible"
        width="400"
        :before-close="handleClose"
    >
      <p>{{$t("L_MAIN.BUTTON_0006")}}</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">{{$t("L_SYSTEM_TIP.TIP_0002")}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "banner",
  data() {
    return {
      height: 0,
      link: null,
      btnText: null,
      btnClass: "",
      dialogTitle: this.$t("L_SYSTEM_TIP.TIP_0001"),
      dialogButton: this.$t("L_SYSTEM_TIP.TIP_0002"),
      dialogVisible: false
    }
  },
  mounted() {
    this.height = this.$getViewportSize().height + "px";
    this.handleRenderButton();
    window.onresize = () => {
      return (() => {
        this.height = this.$getViewportSize().height + "px";
      })();
    };
  },
  computed: {
    userInfo() {
      return this.$store.state.system.userInfo;
    },
    loginStatus() {
      return this.$store.state.system.isLogin;
    },
  },
  methods: {
    handleRenderButton() {
      if (this.userInfo !== "" && this.userInfo.function) {
        let statusArr = this.userInfo.function.split(",");
        if (statusArr[1] === "1") {
          this.link = "#/error?state=2";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0002");
          this.btnClass = "use";
        } else {
          this.link = "javascript:;";
          this.btnText = this.$t("L_PRODUCT.BUTTON_0003");
          this.btnClass = "no";
        }
      } else {
        this.link = "#/login?type=2";
        this.btnText = this.$t("L_PRODUCT.BUTTON_0001");
        this.btnClass = "try";
      }
    },
    handleShowTip() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    linkClick(link) {
      if (!this.loginStatus) {
        this.$router.push({ path: "/login?type=2" });
      } else {
        if (window.localStorage.larkview_userInfo && JSON.parse(window.localStorage.larkview_userInfo).value !== "") {
          window.open(link);
        } else {
          this.$router.push({ path: "/error" });
          return;
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/pc/product/house/banner.scss";
</style>